export const replaceDomain = (url: string, newDomain: string) => {
  try {
    // Parse the URL
    const parsedUrl = new URL(url);

    // Replace the hostname with the new domain
    parsedUrl.hostname = newDomain;

    // Return the modified URL as a string
    return parsedUrl.toString();
  } catch (error) {
    console.error('Invalid URL:', error);
    return null; // Return null if the input is not a valid URL
  }
};
